<template>
  <div class="useRegistration">
    <van-nav-bar title="设备使用记录" left-text="返回" @click-left="back" fixed left-arrow style="z-index:1000" />

  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  mounted() {
  },
  methods: {
    back() {
      history.back();
    },
  },
};
</script>

<style lang="less" scoped></style>